import React, {useState, useEffect} from 'react';
import {colors} from '../utils/index';
import Header from './Header';
import moment from 'moment';
import './Main.css';
import Loader from 'react-js-loader';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CustomButton from '../utils/CustomButton';

const RegisterVouchers = () => {
  const mincel = window.innerWidth < 900;
  const [isLoading, setIsLoading] = useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [userClubData, setUserClubData] = useState([]);

  const [popupOpenError, setPopupOpenError] = useState(false);
  const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [buttonColor, setButtonColor] = useState(colors.RED);
  const [title, setTitle] = useState('REGISTRA VOUCHER');
  const [allVouchers, setAllVouchers] = useState([]);
  const [formBackgroundColor, setFormBackgroundColor] = useState(colors.CARD);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [searching, setSearching] = useState(true);
  const [sendLoginType, setSendLoginType] = useState(true);
  const [userData, setUserData] = useState({
    nameVoucher: '',
    id: '',
    dni: '',
    description: '',
    value: '',
    category: '',
    nameCategory: '',
    firstName: '',
    lastName: '',
    fechaDesde: new Date(),
    fechaHasta: new Date(),
    horaDesde: '10:00',
    horaHasta: '00:00',
    valueType: '1',
    quantity: 1,
  });

  useEffect(() => {
    loadAllUsers(); // Carga todos los vouchers al inicio
  }, []);

  const loadAllUsers = async () => {
    try {
      setIsLoading(true);
      const body = {};

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultVouchers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();
      setIsLoading(false);

      if (data.error) {
        setMensaje(data.error === 'No se encontraron Vouchers' ? 'No hay Vouchers' : 'Error en carga de Vouchers');
        setTitle('REGISTRA VOUCHER');
        setPopupOpenError(true);
      } else {
        setAllVouchers(data.data);
      }
    } catch (error) {
      console.error('Error al cargar los vouchers:', error);
    }
  };

  const handleDeleteButtonClick = () => {
    setFormBackgroundColor(colors.LIGHT_GREEN);
    setTitle('ELIMINA VOUCHER');
    setSearchEnabled(true);
  };

  const handleRegisterButtonClick = () => {
    setSearchEnabled(false);
    setFormBackgroundColor(colors.CARD);
    setTitle('REGISTRA VOUCHER');
  };

  const cleanForm = () => {
    setUserData({
      firstName: '',
      lastName: '',
      dni: '',
      searching: true,
      nameVoucher: '',
      id: '',
      description: '',
      value: '',
      category: '',
      nameCategory: '',
      fechaDesde: new Date(),
      fechaHasta: new Date(),
      horaDesde: '10:00',
      horaHasta: '00:00',
      valueType: '1',
      quantity: 1,
    });
  };

  useEffect(() => {
    const fetchOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    };

    Promise.all([fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultVouchersCat', fetchOptions), fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultUsersClubNames', fetchOptions)])
      .then(([categoriesResponse, usersClubResponse]) => Promise.all([categoriesResponse.json(), usersClubResponse.json()]))
      .then(([categoryData, usersClubData]) => {
        if (categoryData.error) {
          console.error('Error al obtener las opciones de Category:', categoryData.error);
        } else {
          setCategoryOptions(categoryData.data.map(item => ({id: item.id, nameCategory: `${item.nameCategory}`})));
        }

        if (usersClubData.error) {
          console.error('Error al obtener los usuarios del club:', usersClubData.error);
        } else if (Array.isArray(usersClubData.data)) {
          setUserClubData(
            usersClubData.data.map(user => ({
              dni: user.dni,
              firstName: user.firstName,
              lastName: user.lastName,
            })),
          );
        } else {
          console.error('Los datos de los usuarios del club no están en el formato esperado:', usersClubData);
        }
      })
      .catch(error => console.error('Error al obtener las opciones de Category y los usuarios del club:', error));
  }, []);

  const handleCategoryChange = (e, selectedOption) => {
    const value = selectedOption ? selectedOption.value : e.target.value;
    const selectedCategory = categoryOptions.find(category => category.id === value);

    if (selectedCategory) {
      setUserData(prevUserData => ({
        ...prevUserData,
        category: selectedCategory.id,
        nameCategory: selectedCategory.nameCategory,
      }));
    }
  };

  const handleVoucherChange = async (e, selectedOption) => {

    const value = selectedOption ? selectedOption.value : e.target.value;
    const selectedVoucher = allVouchers.find(voucher => voucher.idT === value);

    if (selectedVoucher) {
      const fechaDesdeParts = selectedVoucher.fechaDesdeT.split('*');
      const fechaHastaParts = selectedVoucher.fechaHastaT.split('*');
      const fechaDesdeFormat = fechaDesdeParts[0].split('/').join('-');
      const fechaHastaFormat = fechaHastaParts[0].split('/').join('-');
      const valQuantity = selectedVoucher.quantity <= 0 ? 1 : selectedVoucher.quantity;
      const selectedCategory = categoryOptions.find(category => category.id === selectedVoucher.category);

      setUserData(prevUserData => ({
        ...prevUserData,
        nameVoucher: selectedVoucher.nameVoucher,
        description: selectedVoucher.description,
        fechaDesde: fechaDesdeFormat,
        horaDesde: fechaDesdeParts[1],
        fechaHasta: fechaHastaFormat,
        horaHasta: fechaHastaParts[1],
        valueType: selectedVoucher.valueType,
        value: selectedVoucher.value,
        category: selectedCategory ? selectedCategory.id : '',
        nameCategory: selectedCategory ? selectedCategory.nameCategory : '',
        idT: selectedVoucher.idT,
        quantity: valQuantity,
      }));
    }
  };

	const handleUserChange = async (e, selectedOption) => {

    const value = selectedOption ? selectedOption.value : e.target.value;
    const selectedUser = userClubData.find(user => user.dni === value);

    if (selectedUser) {
      setUserData(prevUserData => ({
        ...prevUserData,
        firstName: selectedUser.firstName,
        lastName: selectedUser.lastName,
        dni: selectedUser.dni,
      }));
    }
  };

  const handleDeleteUser = async () => {
    try {
      setIsLoading(true);
      const body = [
        {
          id: userData.idT,
          nameVoucher: moment(userData.nameVoucher).format('YYYY/MM/DD'),
        },
      ];

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteVoucher', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      setIsLoading(false);
      if (!response.ok) {
        setMensaje(response.error);
        setButtonColor(colors.RED);
        setPopupOpenError(true);
        cleanForm();
      } else {
        setMensaje('Voucher eliminado correctamente');
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
        loadAllUsers();
        cleanForm();
      }
    } catch (error) {
      console.error('Error al eliminar Voucher:', error.message);
    }
  };

  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setPopupOpenYesorNo(false);
    setIsLoading(false);
  };

  const handlePopupYesorNo = async () => {
    if (title === 'ELIMINA VOUCHER') {
      handleDeleteUser();
    } else {
      handleSubmit();
    }
  };

  const validateData = async () => {
    if (userData.nameVoucher === '' || userData.description === '' || (userData.dni === '' && searching)) {
      setMensaje('Complete los datos');
      setPopupOpenError(true);
      return false;
    }
    if (userData.quantity <= 0) {
      setMensaje('Quantity no debe ser menor o igual a 0');
      setPopupOpenError(true);
    }

    return true;
  };

  const handleButton = async e => {
    if (title === 'ELIMINA VOUCHER') {
      setMensaje('ELIMINA VOUCHER: ' + userData.nameVoucher + ' ' + userData.fechaDesde + '?');
      setPopupOpenYesorNo(true);
    } else if (title === 'MODIFICA VOUCHER') {
      setMensaje('MODIFICA VOUCHER: ' + userData.nameVoucher + ' ' + userData.fechaDesde + '?');
      setPopupOpenYesorNo(true);
    } else {
      const mensajeVerificador = 'Esta Seguro de crear cantidad de Vouchers: ' + userData.quantity + ' de ' + userData.nameVoucher + '?';
      setMensaje(mensajeVerificador);
      setPopupOpenYesorNo(true);
    }
  };

  function formatFecha(fecha) {
    return moment(fecha).format('YYYY/MM/DD');
  }

  function formatHora(hora) {
    return moment(hora, 'HH:mm').format('HH:mm:ss');
  }

  const handleSubmit = async e => {
    const isValid = await validateData();
    if (!isValid) {
      return;
    }

    if (userData.fechaDesde > userData.fechaHasta) {
      setMensaje('La fecha de inicio debe ser anterior a la fecha de Vencimiento');
      setPopupOpenError(true);
      return;
    }

    setIsLoading(true);
    const body = {
      dni: searching ? userData.dni : 'all',
      nameVoucher: userData.nameVoucher,
      id: userData.idT,
      description: userData.description,
      valueType: userData.valueType,
      value: userData.value,
      fechaDesde: formatFecha(userData.fechaDesde),
      fechaHasta: formatFecha(userData.fechaHasta),
      horaDesde: formatHora(userData.horaDesde),
      horaHasta: formatHora(userData.horaHasta),
      category: userData.category,
      quantity: userData.quantity.toString(),
      sendLoginType: sendLoginType,
      rrpp: localStorage.dni,
    };

// console.log(body)
    const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/registerMyVouchers', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    const data = await response.json();

    setIsLoading(false);
    if (data.error) {
      setMensaje(data.error);
      setButtonColor(colors.RED);
      setPopupOpenError(true);
      cleanForm();
    } else {
      setMensaje('ALTA EXITOSA');
      setButtonColor(colors.GREEN);
      setPopupOpenError(true);
      loadAllUsers();
      cleanForm();
    }
  };

  const formatedDateDesde = moment(userData.fechaDesde).format('YYYY-MM-DD');
  const formatedDateHasta = moment(userData.fechaHasta).format('YYYY-MM-DD');
  const back = colors.backGroundImage;

  return (
    <div style={{backgroundImage: `url(${back})`, backgroundSize: '100vw 100vh', height: mincel ? '100%' : '100vh', width: '100%', overflow: 'auto'}}>
      <Header pageTitle={title} showBackButton={true} />
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: mincel ? 20 : '-15px'}}>
        <div style={{zIndex: 100, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: mincel ? '100vw' : '90vw', flex: 1, marginTop: mincel ? 0 : '50px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', backgroundColor: formBackgroundColor, borderRadius: '40px'}}>
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={handleRegisterButtonClick} className="button-pest" style={{backgroundColor: colors.CARD}}>
                AGREGAR
              </button>
              <button onClick={handleDeleteButtonClick} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
            </div>
            <div style={{marginTop: '-20px', fontSize: '2em'}}>
              <p style={{color: 'white', fontSize: mincel ? '1.1em' : '0.8em', fontFamily: 'HelveticaNeue-Bold', marginLeft: '10px'}}>{title}</p>
            </div>

            <div style={{flexDirection: mincel ? 'column' : 'row', marginTop: -15, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', backgroundColor: colors.MINICARD, padding: 10, borderRadius: '20px'}}>
              <div style={{marginTop: mincel ? '10px' : '-15px', display: 'flex', alignItems: 'center'}}>
                <input type="checkbox" style={{width: '20px', height: '20px'}} checked={!searchEnabled} onChange={() => setSearchEnabled(!searchEnabled)} />
                <p className="textForm" style={{fontSize: mincel ? '1em' : '1.2em', marginLeft: '10px'}}>
                  CREAR Nuevo Voucher
                </p>
              </div>
              <div style={{alignItems: 'start', display: 'flex', flexDirection: mincel ? 'column' : 'row'}}>
                <p className="textForm" style={{marginTop: '10px', marginRight: '10px', fontSize: mincel ? '1em' : '1.2em'}}>
                  Buscar Voucher
                </p>
                {searchEnabled ? (
                  <Autocomplete
                    sx={{width: mincel ? '90vw' : '30vw', marginTop: '5px', backgroundColor: '#fff', border: '2px solid #000'}}
                    value={allVouchers.find(voucher => voucher.idT === userData.idT) || null} // Asegúrate de que el valor inicial corresponda al estado actual
                    id="nameVoucher"
                    onChange={(e, selectedOption) => {
                      if (selectedOption) {
                        setUserData(prevUserData => ({
                          ...prevUserData,
                          nameVoucher: selectedOption.nameVoucher,
                          description: selectedOption.description,
                          fechaDesde: selectedOption.fechaDesde,
                          horaDesde: selectedOption.fechaDesdeT.split('*')[1],
                          fechaHasta: selectedOption.fechaHasta,
                          horaHasta: selectedOption.fechaHastaT.split('*')[1],
                          valueType: selectedOption.valueType,
                          value: selectedOption.value,
                          category: selectedOption.category,
                          idT: selectedOption.idT,
                          quantity: selectedOption.quantity || 1,
                        }));
                      } else {
                        // Maneja el caso donde no hay selección (puede ser útil en algunos casos)
                        setUserData(prevUserData => ({
                          ...prevUserData,
                          nameVoucher: '',
                          description: '',
                          fechaDesde: '',
                          horaDesde: '',
                          fechaHasta: '',
                          horaHasta: '',
                          valueType: '',
                          value: '',
                          category: '',
                          idT: '',
                          quantity: 1,
                        }));
                      }
                    }}
                    isOptionEqualToValue={(option, value) => option.idT === value.idT}
                    options={allVouchers}
                    getOptionLabel={option => option.nameVoucher}
                    renderInput={params => <TextField {...params} label="Seleccione VOUCHER" variant="outlined" />}
                  />
                ) : (
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="nameVoucher" placeholder="Nombre" onChange={handleVoucherChange} value={userData.nameVoucher || ''} />
                )}
              </div>
            </div>
            <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px'}}>
              <div style={{display: 'flex', flexDirection: mincel ? 'column' : 'row', opacity: !searchEnabled ? 1 : 0.5, pointerEvents: !searchEnabled ? 'auto' : 'none'}}>
                <p style={{marginTop: '20px', padding: 5, color: '#000'}} className="textTerm">
                  CATEGORY
                </p>
                <Autocomplete style={{width: mincel ? '90vw' : '30vw', marginTop: '10px', backgroundColor: '#fff', border: '2px solid #000'}} value={userData.nameCategory} onChange={handleCategoryChange} name="category" id="category" isOptionEqualToValue={(option, value) => option && option.category === value.category} options={categoryOptions.map(user => ({label: user.nameCategory, value: user.id, id: 'category'}))} renderInput={params => <TextField {...params} label="Seleccione CATEGORÍA" variant="outlined" />} />
              </div>

              <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: mincel ? 'column' : 'row'}}>
                <p style={{marginTop: '10px', padding: 5}} className="textTerm">
                  QUANTITY
                </p>
                <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '50%', border: 'none'}} type="number" maxLength={3} required id="quantity" placeholder="xxxx" onChange={e => setUserData({...userData, quantity: e.target.value})} value={userData.quantity !== undefined ? userData.quantity : 1} />
              </div>
            </div>
            <div style={{opacity: !searchEnabled ? 1 : 0.5, pointerEvents: !searchEnabled ? 'auto' : 'none'}}>
              <div style={{marginTop: '-5px', display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', opacity: !searchEnabled ? 1 : 0.5, pointerEvents: !searchEnabled ? 'auto' : 'none'}}>
                <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column', marginTop: 5}}>
                  <p className="textTerm">Descripción</p>
                  <p className="textFormError">{userData.description === '' ? 'Campo requerido' : ''}</p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none', marginTop: -15}} type="textArea" required id="description" placeholder="xxxxx" onChange={e => setUserData({...userData, description: e.target.value})} maxLength={140} value={userData.description ?? ''} />
                </div>
              </div>
              <div style={{marginTop: -5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
                <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                  <p style={{marginTop: '10px', width: '200px'}} className="textTerm">
                    Fecha Desde
                  </p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="fechaDesde" placeholder="dd-mm-yyyy" onChange={e => setUserData({...userData, fechaDesde: e.target.value})} value={formatedDateDesde} />
                </div>
                <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                  <p style={{marginTop: '10px'}} className="textTerm">
                    Hora Desde
                  </p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="time" required id="horaDesde" placeholder="00:00" onChange={e => setUserData({...userData, horaDesde: e.target.value})} value={userData.horaDesde} />
                </div>
              </div>

              <div style={{marginTop: -5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
                <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                  <p style={{marginTop: '10px'}} className="textTerm">
                    Fecha Hasta
                  </p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="date" required id="fechaHasta" placeholder="dd-mm-yyyy" onChange={e => setUserData({...userData, fechaHasta: e.target.value})} value={formatedDateHasta} />
                </div>
                <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'row'}}>
                  <p style={{marginTop: '10px'}} className="textTerm">
                    Hora Hasta
                  </p>
                  <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="time" required id="horaHasta" placeholder="00:00" onChange={e => setUserData({...userData, horaHasta: e.target.value})} value={userData.horaHasta} />
                </div>
              </div>
              {userData.category === '1' && (
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <p style={{marginTop: '10px'}} className="textTerm">
                      Valor
                    </p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="number" required id="value" placeholder="$" onChange={e => setUserData({...userData, value: e.target.value})} value={userData.value} />
                  </div>
                </div>
              )}
            </div>
            {title !== 'ELIMINA VOUCHER' && (
              <div style={{maxWidth: mincel ? 350 : '', marginTop: '20px', display: 'grid', alignItems: 'center', backgroundColor: colors.MINICARD, padding: 20, borderRadius: 20}}>
                <div
                  style={{
                    maxWidth: mincel ? 350 : '',
                    marginTop: '20px',
                    display: 'grid',
                    alignItems: 'center',
                    backgroundColor: colors.MINICARD,
                    padding: 20,
                    borderRadius: 20,
                    gridTemplateColumns: mincel ? '1fr 1fr' : 'repeat(4, 1fr)',
                    gap: '10px', // Espaciado entre los elementos
                  }}>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      type="radio"
                      style={{width: '20px', height: '20px'}}
                      checked={searching === false && sendLoginType === 'all'}
                      onChange={() => {
                        setSearching(false);
                        setSendLoginType('all');
                      }}
                    />
                    <p style={{color: 'white', fontSize: '1em', marginLeft: '10px'}}>TODOS</p>
                  </div>

                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      type="radio"
                      style={{width: '20px', height: '20px', marginLeft: '20px'}}
                      checked={searching === false && sendLoginType === '10'}
                      onChange={() => {
                        setSearching(false);
                        setSendLoginType('10');
                      }}
                    />
                    <p style={{color: 'white', fontSize: '1em', marginLeft: '10px'}}>MEMBER</p>
                  </div>

                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      type="radio"
                      style={{width: '20px', height: '20px', marginLeft: '10px'}}
                      checked={searching === false && sendLoginType === '12'}
                      onChange={() => {
                        setSearching(false);
                        setSendLoginType('12');
                      }}
                    />
                    <p style={{color: 'white', fontSize: '1em', marginLeft: '10px'}}>EVOLUTION</p>
                  </div>

                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      type="radio"
                      style={{width: '20px', height: '20px', marginLeft: '20px'}}
                      checked={searching === true && sendLoginType === ''}
                      onChange={() => {
                        setSearching(true);
                        setSendLoginType('');
                      }}
                    />
                    <p style={{color: 'white', fontSize: '1em', marginLeft: '10px'}}>INDIVIDUAL</p>
                  </div>
			</div>


                <div style={{flexDirection: 'column', marginTop: '-10px', display: 'grid', gridTemplateColumns: mincel ? '' : '1fr 1fr', columnGap: '10px', opacity: searching ? 1 : 0.5, pointerEvents: searching ? 'auto' : 'none'}}>
                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <p className="textTerm">NOMBRE</p>
                    <Autocomplete
                      sx={{width: mincel ? '80vw' : '30vw', marginTop: '10px', backgroundColor: '#fff', border: '2px solid #000'}}
                      value={userData.firstName ? userClubData.find(user => user.firstName === userData.firstName) : null}
                      onChange={(e, selectedOption) => handleUserChange(e, {id: 'firstName', value: selectedOption?.dni})}
                      isOptionEqualToValue={(option, value) => option.dni === value.dni}
                      options={userClubData}
                      getOptionLabel={option => option?.firstName || ''}
                      renderOption={(props, option) => (
                        <li {...props} key={option.dni}>
                          {option.firstName}
                        </li>
                      )}
                      renderInput={params => <TextField {...params} label="Seleccione NOMBRE" variant="outlined" />}
                    />
                  </div>

                  <div style={{padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <p className="textTerm">APELLIDO</p>
                    <Autocomplete
                      sx={{width: mincel ? '80vw' : '30vw', marginTop: '10px', backgroundColor: '#fff', border: '2px solid #000'}}
                      value={userData.lastName ? userClubData.find(user => user.lastName === userData.lastName) : null}
                      onChange={(e, selectedOption) => handleUserChange(e, {id: 'lastName', value: selectedOption?.dni})}
                      isOptionEqualToValue={(option, value) => option.dni === value.dni}
                      options={userClubData}
                      getOptionLabel={option => option?.lastName || ''}
                      renderOption={(props, option) => (
                        <li {...props} key={option.dni}>
                          {option.lastName}
                        </li>
                      )}
                      renderInput={params => <TextField {...params} label="Seleccione APELLIDO" variant="outlined" />}
                    />
                  </div>
                </div>
              </div>
            )}

            <div style={{marginTop: '35px', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px'}}>
              <div style={{textAlign: 'center'}}>
                <CustomButton onPress={handleButton} title={title === 'REGISTRA VOUCHER' ? 'REGISTRAR' : 'ELIMINAR'} color={title === 'REGISTRA VOUCHER' ? colors.MARRON_OSCURO : colors.MARRON_OSCURO} fontSize={18} buttonWidth={200} buttonStyle={{padding: 0, cursor: 'pointer', border: 'none'}} />
              </div>
            </div>

            {popupOpenError && (
              <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
                  <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                    <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                      OK
                    </button>
                  </div>
                </div>
              </div>
            )}

            {popupOpenYesorNo && (
              <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
                  <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                    <button
                      className="button-mensaje"
                      style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                      onClick={() => {
                        handlePopupYesorNo();
                        handlePopupCloseError();
                      }}>
                      SI
                    </button>
                    <button
                      className="button-mensaje"
                      style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}}
                      onClick={() => {
                        handlePopupCloseError();
                      }}>
                      NO
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterVouchers;
