import React, { useState, useEffect, useMemo , useCallback} from "react";
import { useTable, useFilters, useGlobalFilter } from "react-table";
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
import Loader from "react-js-loader";
import Header from "./Header";
import { colors } from "../utils/index";
import "./Main.css";
// eslint-disable-next-line
import trashcan from '../../assets/imgs/TRASH-01.png'
import CustomButton from '../utils/CustomButton';
const ReportEvolution = () => {
	// eslint-disable-next-line
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const mincel = window.innerWidth < 900;
	const [popupOpenError, setPopupOpenError] = useState(false);
	const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
	const [mensaje, setMensaje] = useState("");
	const [selectAllHeader, setSelectAllHeader] = useState(false);
		// eslint-disable-next-line
	const [buttonColor, setButtonColor] = useState(colors.RED);
	const [title, setTitle] = useState("EVOLUTION a MEMBER");

// eslint-disable-next-line
	const [filteredColumn, setFilteredColumn] = useState("");
	const [responseData, setResponseData] = useState(null);
		// eslint-disable-next-line
	const [dni, setDni] = useState(null);
	// eslint-disable-next-line
	const [selectAll, setSelectAll] = useState(false);
    const [dnisToDelete, setDnisToDelete] = useState([]);
	const [filteredRecords, setFilteredRecords] = useState([]);
	const [formBackgroundColor, setFormBackgroundColor] = useState(colors.MARRON_OSCURO);


 const defaultColumn = useMemo(
   () => ({
     Filter: ({ column }) => {
       const { filterValue, setFilter } = column;
       return (
         <input
           value={filterValue || ""}
           onChange={(e) => setFilter(e.target.value)}
           placeholder={`Filtrar por ${column.Header}`}
           onKeyDown={(e) => {
             if (e.key === "Enter") {
               const filterText = e.target.value;
               setFilter(filterText);
             }
           }}
         />
       );
     },
     filter: (rows, dni, filterValue) => {
       return rows.filter((row) => {
         const rowValue = row.values[dni];
         return rowValue.toLowerCase().startsWith(filterValue.toLowerCase());
       });
     },
   }),
   []
 );

 useEffect(() => {
   loadAllUsers();
 }, []);

 const loadAllUsers = async () => {
   try {
     setIsLoading(true);
     const body = {};
     const response = await fetch(
       "https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultEvolution",
       {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
         },
         body: JSON.stringify(body),
       }
     );
     const data = await response.json();
     setIsLoading(false);
     if (data.error) {
       if (data.error === "No se encontraron Referidos") {
         setMensaje("No hay Referidos");
         setTitle("REVOLUTION a MEMBER");
         setPopupOpenError(true);
       } else if (data.error !== "No se encontraron Referidos") {
         setMensaje("Error en carga de Referidos");
         setTitle("EVOLUTION a MEMBER");
         setPopupOpenError(true);
       }
     } else {
       setResponseData(data.data);
     }
   } catch (error) {
     console.error("Error al cargar los dnis:", error);
   }
 };

	const handleSelectReferido = useCallback(
    (dniId) => {
      // Toggle selection of a voucher
      if (dnisToDelete.includes(dniId)) {
        // If already selected, remove from selection
        setDnisToDelete(dnisToDelete.filter((dni) => dni !== dniId));
      } else {
        // If not selected, add to selectiondnisToDelete
        setDnisToDelete([...dnisToDelete, dniId]);
      }
    },
    [dnisToDelete]
  );

  const handleSelectAll = useCallback(() => {
    const newValue = !selectAllHeader;
    setSelectAllHeader(newValue);
    setSelectAll(newValue);
    const allReferidoIds = responseData.map((referido) => referido.dni); // Obtener todos los IDs de los vouchers
    setDnisToDelete(newValue ? allReferidoIds : []);

    // Marcar todos los vouchers si newValue es true, de lo contrario, vaciar la lista de IDs
    // Llamar a handleDeleteVoucher para eliminar todos los vouchers marcados
  }, [selectAllHeader, responseData ]);


const columns = useMemo(
  () => [
    {
      Header: () => (
        <div style={{ marginTop: "-20px" }}>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={() => handleSelectAll()}
          />{" "}
          TODOS
        </div>
      ),
      disableFilters: true,
      Cell: ({ row }) => (
        <input
          type="checkbox"
          checked={
            selectAllHeader ||
            selectAll ||
            dnisToDelete.includes(row.original.dni)
          }
          onChange={() => handleSelectReferido(row.original.dni)}
        />
      ),
      accessor: "checkbox", // Unique accessor for the checkbox column
    },
    { Header: "DNI", accessor: "dni", style: { textAlign: "left" } },
    { Header: "NOMBRE", accessor: "first_name", style: { textAlign: "left" } },
    { Header: "APELLIDO", accessor: "last_name", style: { textAlign: "left" } },
    { Header: "REFERIDO", accessor: "user", style: { textAlign: "left" } },
    {
      Header: "FECHA NACIMIENTO",
      accessor: "birthDate",
      style: { textAlign: "center" },
    },
    {
      Header: "SEXO",
      accessor: "sexo",
      style: { textAlign: "center" },
    },
    {
      Header: "INSTAGRAM",
      accessor: "ig",
      style: { textAlign: "center" },
    },
  ],
  [
    dnisToDelete,
    handleSelectReferido,
    handleSelectAll,
    selectAll,
    selectAllHeader,
  ]
);


const tableData = useMemo(() => {
  return {
    columns,
    data: Array.isArray(responseData) ? responseData : [],
    defaultColumn,
  };
}, [responseData, columns, defaultColumn]);




// eslint-disable-next-line
const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state: { globalFilter } } = useTable(tableData, useFilters, useGlobalFilter);


  useEffect(() => {
    setFilteredRecords(
      rows
        .map((row) => row.original)
        .filter((record) => dnisToDelete.includes(record.dni))
    );
  }, [rows, dnisToDelete]);

// eslint-disable-next-line
    const handleExport = () => {
      if (responseData !== null && responseData !== "SIN MOVIMIENTOS") {
        const filteredData = rows.map((row) => row.original);
        if (filteredRecords.length > 0) {
          exportToExcel(filteredData);
        } else {
          // Mostrar mensaje de que no hay datos para exportar
          console.log("No hay datos para exportar");
        }
      }
    };



const exportToExcel = (filteredRows) => {
  const exportData = filteredRows.map((row) => ({
    DNI: row.dni.toUpperCase(), // Convert to uppercase
    REFERIDOS: row.user.toUpperCase(),
    NOMBRE: row.first_name.toUpperCase(),
    APELLIDO: row.last_name.toUpperCase(),
    IG: row.ig.toUpperCase(),
  }));

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
  // Insert additional rows at the end for calculations

  // Calculate the sum of payValue column

  XLSX.writeFile(workbook, "report.xlsx");
};

// eslint-disable-next-line
const filteredRowCount = filteredRecords.length;
if (localStorage.dni === "") {
  localStorage.clear();
  document.location.href = "/Login";
}
// eslint-disable-next-line
  const handleDeleteReferido = async () => {
	  try {
	 setIsLoading(true);
		var body = [];
		for (const record of filteredRecords) {
           var bodyItems = {};
           bodyItems["dni"] = record.dni;
           body.push(bodyItems);
          }

     const response = await fetch("https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteEvolution",
       {
         method: "DELETE",
         headers: {
           "Content-Type": "application/json",
         },
         body: JSON.stringify(body),
       }
     );
     const data = await response.json();

	  if (data.error) {
      setButtonColor(colors.RED);
      setMensaje("Error en la eliminación, inténtelo de nuevo.");
      setPopupOpenError(true);
    } else {
      setResponseData((prevData) => {
        // Filtrar los datos para eliminar los que se han seleccionado
        const newData = prevData.filter(
          (referido) => !dnisToDelete.includes(referido.dni)
        );
        return newData;
      });
      setMensaje("Eliminación exitosa");
      setButtonColor(colors.GREEN);
      setPopupOpenError(true);
    }
    setIsLoading(false);
    setDnisToDelete([]);
   } catch (error) {
     console.error("Error al cargar los dni:", error);
   }
  };


	 const handleActualizaReferido = async () => {
     try {
       setIsLoading(true);
       var body = [];

       for (const record of filteredRecords) {
         var bodyItems = {};
         bodyItems["dni"] = record.dni;
         body.push(bodyItems);
       }
        //console.log(body)

       const response = await fetch(
         "https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/updateEvolution",
         {
           method: "POST",
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(body),
         }
       );
       const data = await response.json();

       if (data.error) {
         setButtonColor(colors.RED);
         setMensaje("Error en la modificación, inténtelo de nuevo.");
         setPopupOpenError(true);
       } else {
         setResponseData((prevData) => {
           // Filtrar los datos para eliminar los que se han seleccionado
           const newData = prevData.filter(
             (referido) => !dnisToDelete.includes(referido.dni)
           );
           return newData;
         });
         setMensaje("Modificaión Exitosa");
         setButtonColor(colors.GREEN);
         setPopupOpenError(true);
       }
       setIsLoading(false);
       setDnisToDelete([]);
     } catch (error) {
       console.error("Error al cargar los dni:", error);
     }
   };


const handlePopupCloseError = () => {
  //setPayEliminarNo(false)
  setPopupOpenError(false);
  setPopupOpenYesorNo(false);
};

	const handlePopupYesorNo = async () => {
		if (title === "ELIMINA EVOLUTION") {
      handleDeleteReferido();
    } else {
      handleActualizaReferido();
    }
};


	const handleSubmit = () => {
    // Create an array to store selected dnis
    const selectedReferidoIds = [];

    // Iterate over the rows to find selected rows and push their dnis to the array
    rows.forEach((row) => {
      if (row.isSelected) {
        selectedReferidoIds.push(row.original.idKey); // Assuming 'idKey' is the field containing dni
      }
    });

    // If no rows are selected, show error message
		if (filteredRecords.length === 0) {
			if (title === 'EVOLUTION a MEMBER') {
 				setMensaje("No se han seleccionado Registro para Eliminar.");
			} else {
				 setMensaje("No se han seleccionado Registro para Modificar.");
			}

		setButtonColor(colors.RED);
		setPopupOpenError(true);
		return;
    }

    // If only one row is selected, set the dni for deletion
		if (filteredRecords.length === 1) {
			if (title === "EVOLUTION a MEMBER") {
			 setMensaje("EVOLUTION a MEMBER?");
			} else {
			 setMensaje("ELIMINA EVOLUTION?");
			}
      setDni(selectedReferidoIds[0]);
		} else {

     if (title === "EVOLUTION a MEMBER") {
        setMensaje("MODIFICA " + filteredRecords.length + " EVOLUTION?");
     } else {
       setMensaje("ELIMINA " + filteredRecords.length + " EVOLUTION?");
     }

    }

    setButtonColor(colors.RED);
    setPopupOpenYesorNo(true);
  };


const handleAtualizapup = async () => {
	setFormBackgroundColor(colors.MARRON_OSCURO);
	setTitle("EVOLUTION a MEMBER");

};

const handleElimina = async () => {
	setFormBackgroundColor(colors.LIGHT_GREEN);
	setTitle("ELIMINA EVOLUTION");
};


const back = colors.backGroundImage;

  return (
    <div style={{backgroundImage: `url(${back})`, backgroundSize: '100vw 100vh', height: mincel ? '100vh' : '100vh', width: '100%', overflow: 'auto'}}>
      <Header pageTitle={title} showBackButton={true} />
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '30px'}}>
        {/* Contenido */}
        <div style={{zIndex: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: mincel ? '100vw' : '95vw', flex: 1, marginTop: mincel ? 0 : '10px', flexDirection: 'row', zIndex: 990, maxWidth: '95vw', backgroundColor: formBackgroundColor, borderRadius: '50px'}}>
            {/* Botones de pestaña */}
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={handleAtualizapup} className="button-pest" style={{backgroundColor: colors.MARRON_OSCURO}}>
                MODIFICAR
              </button>
              <button onClick={handleElimina} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
            </div>

            <div style={{color: '#fff', marginTop: '20px', display: 'grid', alignItems: 'center', backgroundColor: formBackgroundColor, padding: 5, borderRadius: 20}}>
              <table {...getTableProps()} style={{maxWidth: '100%', marginTop: '2%'}}>
                <thead>
                  {headerGroups.map(headerGroup => {
                    const {key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
                    return (
                      <tr key={key} {...restHeaderGroupProps}>
                        {headerGroup.headers.map(column => {
                          const {key, ...restColumn} = column.getHeaderProps();
                          return (
                            <th key={key} {...restColumn}>
                              {column.render('Header')}
                              <div style={{width: '100%'}}>{column.canFilter ? column.render('Filter') : null}</div>
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}
                </thead>
                <tbody {...getTableBodyProps}>
                  {rows.map(row => {
                    prepareRow(row);
                    const {key, ...restRowProps} = row.getRowProps();
                    return (
                      <tr key={key} {...restRowProps}>
                        {row.cells.map(cell => {
                          const {key, ...restCellProps} = cell.getCellProps();
                          return (
                            <td key={key} {...restCellProps} style={cell.column.style}>
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div style={{marginTop: '35px', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px'}}></div>
            </div>
          </div>
          <div style={{textAlign: 'center'}}>
            <CustomButton
              onPress={handleSubmit}
              title={title === 'EVOLUTION a MEMBER' ? 'EVOLUTION a MEMBER' : 'ELIMINAR SELECCIONADOS'}
              color={colors.MARRON_OSCURO} // Ajusta el color según sea necesario
              fontSize={18} // Ajusta el tamaño de la fuente si es necesario
              buttonWidth={250} // Ajusta el ancho del botón si es necesario
              buttonStyle={{padding: 0, cursor: 'pointer', border: 'none'}} // Estilos adicionales si es necesario
            />
          </div>
        </div>
        {/* Popups de error y confirmación */}
        {popupOpenError && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}

        {popupOpenYesorNo && (
          <div style={{zIndex: 999, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{backgroundColor: buttonColor, padding: '20px', borderRadius: 40, width: '300px', height: '250px'}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button
                  className="button-mensaje"
                  style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                  onClick={() => {
                    handlePopupYesorNo();
                    handlePopupCloseError();
                  }}>
                  SI
                </button>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}} onClick={handlePopupCloseError}>
                  NO
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportEvolution;
