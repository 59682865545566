import React, {useState, useEffect, useMemo, useCallback} from 'react';
import {useTable, useFilters, useGlobalFilter, usePagination} from 'react-table';
import * as XLSX from 'xlsx';
import Loader from 'react-js-loader';
import Header from './Header';
import {colors} from '../utils/index';
import './Main.css';
import AWS from 'aws-sdk';
import CustomButton from '../utils/CustomButton';
const CrudCategories = () => {
	  const mincel = window.innerWidth < 900;
  const [isLoading, setIsLoading] = useState(false);
  const [popupOpenError, setPopupOpenError] = useState(false);
  const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
  const [mensaje, setMensaje] = useState('');
  const [selectAllHeader, setSelectAllHeader] = useState(false);
  const [buttonColor, setButtonColor] = useState(colors.RED);
  const [title, setTitle] = useState('CONSULTA CATEGORÍAS');
  const [titleExcel, setTitleExcel] = useState('');
  const [responseData, setResponseData] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [categoriesToDelete, setCategoriesToDelete] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [formBackgroundColor, setFormBackgroundColor] = useState(colors.MARRON_OSCURO);
  const [selectedRow, setSelectedRow] = useState({
    id: '',
    name: '',
    image1: '',
  });
  const [selectedImage, setSelectedImage] = useState(null);

  const defaultColumn = useMemo(
    () => ({
      Filter: ({column}) => {
        const {filterValue, setFilter} = column;
        return (
          <input
            value={filterValue || ''}
            onChange={e => setFilter(e.target.value)}
            placeholder={`Filtrar por ${column.Header}`}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                const filterText = e.target.value;
                setFilter(filterText);
              }
            }}
          />
        );
      },
      filter: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue.toLowerCase().startsWith(filterValue.toLowerCase());
        });
      },
    }),
    [],
  );

  useEffect(() => {
    const loadAllCategories = async () => {
      try {
        setIsLoading(true);
        const body = {};
        const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultBenefitCat', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
        const data = await response.json();

        setIsLoading(false);
        if (data.error) {
          setMensaje('Error en carga de categorías');
          setTitle('REGISTRA CATEGORÍAS');
          setPopupOpenError(true);
        } else {

          setResponseData(data.data || []);
          setFilteredRecords(data || []);
        }
      } catch (error) {
        console.error('Error al cargar las categorías:', error);
      }
    };

    loadAllCategories();
  }, []);

  const handleSelectCategory = useCallback(
    categoryId => {
      if (categoriesToDelete.some(cat => cat.id === categoryId)) {
        setCategoriesToDelete(categoriesToDelete.filter(cat => cat.id !== categoryId));
      } else {
        const category = responseData.find(cat => cat.id === categoryId);
        setCategoriesToDelete([...categoriesToDelete, category]);
      }
    },
    [categoriesToDelete, responseData],
  );

  const handleSelectAll = useCallback(() => {
    const newValue = !selectAllHeader;
    setSelectAllHeader(newValue);
    setSelectAll(newValue);
    setCategoriesToDelete(newValue ? responseData : []);
  }, [selectAllHeader, responseData]);

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <div style={{marginTop: '-20px'}}>
            <input type="checkbox" checked={selectAll} onChange={() => handleSelectAll()} />
            {titleExcel}
          </div>
        ),
        disableFilters: true,
        Cell: ({row}) => <input type="checkbox" checked={selectAllHeader || selectAll || categoriesToDelete.some(cat => cat.id === row.original.id)} onChange={() => handleSelectCategory(row.original.id)} />,
        accessor: 'checkbox',
      },
      {Header: 'ID', accessor: 'id', style: {textAlign: 'left'}},
      {Header: 'NOMBRE', accessor: 'name', style: {textAlign: 'left'}},
      {Header: 'IMAGEN', accessor: 'image1', style: {textAlign: 'center'}, Cell: ({value}) => <img src={value} alt="Imagen" style={{width: '50px'}} />},
    ],
    [categoriesToDelete, handleSelectCategory, handleSelectAll, selectAll, selectAllHeader, titleExcel],
  );

  const tableData = useMemo(() => {
    return {
      columns,
      data: Array.isArray(responseData) ? responseData : [],
      defaultColumn,
    };
  }, [responseData, columns, defaultColumn]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize},
  } = useTable(tableData, useFilters, useGlobalFilter, usePagination);

  useEffect(() => {
    if (title === 'ELIMINA CATEGORÍAS') {
      setFilteredRecords(rows.map(row => row.original).filter(record => categoriesToDelete.some(cat => cat.id === record.id)));
    } else {
      setFilteredRecords(rows.map(row => row.original));
    }
  }, [rows, categoriesToDelete, title, setPageSize]);

  const handleExport = () => {
    if (filteredRecords !== null && filteredRecords !== 'SIN MOVIMIENTOS') {
      const filteredData = filteredRecords.length > 0 ? filteredRecords : rows.map(row => row.original);
      if (filteredData.length > 0) {
        exportToExcel(filteredData);
      } else {
        console.log('No hay datos para exportar');
      }
    }
  };

  const exportToExcel = filteredRows => {
    const exportData = filteredRows.map(row => ({
      ID: row.id,
      NOMBRE: row.name,
      IMAGEN: row.image1,
    }));

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    XLSX.writeFile(workbook, 'report.xlsx');
  };

  if (localStorage.dni === '') {
    localStorage.clear();
    document.location.href = '/Login';
  }

  const handleDeleteCategory = async () => {
    try {
      setIsLoading(true);
      const body = categoriesToDelete.map(cat => ({id: cat.id, name: cat.name}));

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteBenefitCat', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      if (data.error) {
        setButtonColor(colors.RED);
        setMensaje('Error en la eliminación, inténtelo de nuevo.');
        setPopupOpenError(true);
      } else {
        setResponseData(prevData => prevData.filter(category => !categoriesToDelete.some(cat => cat.id === category.id)));
        setFilteredRecords(prevData => prevData.filter(category => !categoriesToDelete.some(cat => cat.id === category.id)));
        setMensaje('Eliminación exitosa');
        handleClear();
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
      }
      setIsLoading(false);
      setCategoriesToDelete([]);
    } catch (error) {
      console.error('Error al eliminar las categorías:', error);
      setIsLoading(false);
    }
  };

  const handlePopupCloseError = () => {
    setPopupOpenError(false);
    setPopupOpenYesorNo(false);
  };

  const handlePopupYesorNo = async () => {
    if (title === 'ELIMINA CATEGORÍAS') {
      handleDeleteCategory();
    } else {
      handleExport();
    }
  };

  const handleSubmit = async () => {
    try {
      const isValid = await validateData();
      if (!isValid) {
        return; // No ejecutar el resto de la lógica si la validación falla
      }

      const existingCategory = responseData.find(category => category.id === selectedRow.id);

      if (existingCategory) {
        // Update existing category
        if (title === 'ELIMINAR CATEGORÍAS') {
          await handleDeleteCategory();
        }
      } else {
        // Register new category
        await handleRegister();
      }
    } catch (error) {
      console.error('Error al procesar el formulario:', error.message);
    }
  };

  const handleAtualizapup = async () => {
    setFormBackgroundColor(colors.MARRON_OSCURO);
    setTitle('CONSULTA CATEGORÍAS');
    setTitleExcel('');
    setSelectedRow({
      id: '',
      name: '',
      image1: '',
    });
    setSelectedImage(null);
  };

  const handleElimina = async () => {
    setFormBackgroundColor(colors.LIGHT_GREEN);
    setTitle('ELIMINA CATEGORÍAS');
    setTitleExcel('TODOS');
    setSelectedRow({
      id: '',
      name: '',
      image1: '',
    });
    setSelectedImage(null);
  };

  const handleClear = () => {
    setSelectedRow({
      id: '',
      name: '',
      image1: '',
    });
    setSelectedImage(null);
  };

  const handleRowClick = row => {
    setSelectedRow({
      id: row.id || '',
      name: row.name || '',
      image1: row.image1 || '',
    });
    setSelectedImage(null);
  };

  const handleImageChange = e => {
    if (e.target.files && e.target.files[0]) {
      setSelectedImage(e.target.files[0]);
    } else {
      setSelectedImage(null);
    }
  };

  const handleRegister = async e => {
    try {
      const isValid = await validateData();
      if (!isValid) {
        return; // No ejecutar el resto de la lógica si la validación falla
      }

      const image1 = await uploadToS3(selectedImage);

      const body = {
        id: selectedRow.id,
        name: selectedRow.name,
        image1: image1,
      };

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/registerBenefitCat', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();

      setIsLoading(false);
      if (data.error) {
        setMensaje(data.error);
        setButtonColor(colors.RED);
        setPopupOpenError(true);
	  } else if (data.data) {
		const newCategory = data.data
        setResponseData(prevData => [...prevData, newCategory]);
        setFilteredRecords(prevData => [...prevData, newCategory]);
        setMensaje('ALTA EXITOSA');
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
        handleClear();
      } else {
        setMensaje('Error de Servidor, Intente Nuevamente');
        setButtonColor(colors.RED);
        setPopupOpenError(true);
      }
    } catch (error) {
      console.error('Error al registrar categoría:', error.message);
    }
  };

  const uploadToS3 = async file => {
    if (!file) {
      return null;
    }

    const s3 = new AWS.S3({
      bucket: 'buckettree/catch/beneficios', // Ex. aboutreact
      region: 'us-east-1', // Ex. ap-south-1
      accessKeyId: 'AKIAUJDBTKTO623KUEMP',
      secretAccessKey: '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',
      successActionStatus: 201,
    });

    const params = {
      Bucket: 'buckettree/catch/beneficios',
      Key: file.name,
      Body: file,
      ACL: 'public-read',
    };

    try {
      const data = await s3.upload(params).promise();
      return data.Location;
    } catch (error) {
      console.error('Error al subir imagen a S3:', error);
      return null;
    }
  };
  // eslint-disable-next-line
  const handleUpdateCategory = async () => {
    try {
      const isValid = await validateData();
      if (!isValid) {
        return; // No ejecutar el resto de la lógica si la validación falla
      }

      setIsLoading(true);

      const image1 = await uploadToS3(selectedImage);

      const body = {
        id: selectedRow.id,
        name: selectedRow.name,
        image1: image1 || selectedRow.image1,
      };

      const response = await fetch('https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/updateCategory', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
      setIsLoading(false);

      if (!response.ok) {
        const errorData = await response.json();
        setMensaje(errorData.error);
        setButtonColor(colors.RED);
        setPopupOpenError(true);
      } else {
        setResponseData(prevData => prevData.map(category => (category.id === selectedRow.id ? selectedRow : category)));
        setFilteredRecords(prevData => prevData.map(category => (category.id === selectedRow.id ? selectedRow : category)));
        setMensaje('Categoría modificada correctamente');
        handleClear();
        setButtonColor(colors.GREEN);
        setPopupOpenError(true);
      }
    } catch (error) {
      console.error('Error al modificar categoría:', error.message);
      setIsLoading(false);
    }
  };

  const validateData = async () => {
    if (selectedRow.name === '' ) {
      setMensaje('Complete los datos');
      setPopupOpenError(true);
      return false;
    }
    return true;
  };

  const back = colors.backGroundImage;

  return (
    <div style={{backgroundImage: `url(${back})`, backgroundSize: 'cover', height: '100vh', width: '100vw'}}>
      <Header pageTitle={title} showBackButton={true} />
      <h3 style={{color: '#fff', textAlign: 'center', justifyContent: 'center', marginTop: -30, marginBottom:30}}>BENEFICIOS</h3>
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '30px'}}>
        <div style={{zIndex: 100, display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: '90vw', flex: 1, marginTop: '10px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', backgroundColor: formBackgroundColor, borderRadius: '50px'}}>
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={handleAtualizapup} className="button-pest" style={{backgroundColor: colors.MARRON_OSCURO}}>
                AGREGAR
              </button>
              <button onClick={handleElimina} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
              <button onClick={handleClear} className="button-pest" style={{backgroundColor: colors.RED}}>
                LIMPIAR
              </button>
            </div>
            {selectedRow && (
              <div style={{marginTop: '20px', padding: '20px', backgroundColor: colors.MINICARD, borderRadius: '20px', boxShadow: '0 0 10px rgba(0,0,0,0.2)', width: '90%', margin: '0 auto'}}>
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns:mincel?'': '1fr 1fr', columnGap: '10px', flexDirection: mincel ? 'column' : 'row'}}>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: mincel ? 'column' : 'row'}}>
                    <label className="textTerm">ID</label>
                    <p style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="id" placeholder="ID" value={selectedRow.id || ''} onChange={e => setSelectedRow({...selectedRow, id: e.target.value})} />
                  </div>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <label className="textTerm">NOMBRE</label>
                    <p className="textFormError">{selectedRow.name === '' ? 'Campo requerido' : ''}</p>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="text" required id="name" placeholder="Nombre" value={selectedRow.name || ''} onChange={e => setSelectedRow({...selectedRow, name: e.target.value})} />
                  </div>
                </div>
                <div style={{marginTop: 5, display: 'grid', gridTemplateColumns: '1fr', columnGap: '10px'}}>
                  <div style={{color: '#000', padding: 5, alignItems: 'start', display: 'flex', flexDirection: 'column'}}>
                    <label className="textTerm">IMAGEN (peso menos a 100Kb 100px x 100px)</label>
                    <input style={{height: 30, borderRadius: 0, borderColor: colors.WHITE, borderWidth: 1, borderStyle: 'solid', backgroundColor: '#fff', width: '100%', border: 'none'}} type="file" required id="image1" onChange={handleImageChange} />
                    {selectedImage && <img src={URL.createObjectURL(selectedImage)} alt="Imagen" style={{width: '100px', marginTop: '10px'}} />}
                  </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '10px'}}>
                  {title === 'ELIMINA CATEGORÍAS' ? (
                    <></>
                  ) : (
                    <CustomButton
                      onPress={handleSubmit}
                      title="AGREGAR CATEGORIA"
                      color={colors.MARRON_OSCURO} // Ajusta el color según sea necesario
                      fontSize={18} // Ajusta el tamaño de la fuente si es necesario
                      buttonWidth={200} // Ajusta el ancho del botón si es necesario
                    />
                  )}
                </div>
              </div>
            )}
            <div style={{marginTop: '20px'}}>
              <div style={{display: 'grid', alignItems: 'center', backgroundColor: formBackgroundColor, padding: 20, borderRadius: 20, maxHeight: '50vh', overflow: 'auto'}}>
                <table {...getTableProps()} style={{maxWidth: '100%', marginTop: '2%'}}>
                  <thead>
                    {headerGroups.map(headerGroup => {
                      const {key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={key} {...restHeaderGroupProps}>
                          {headerGroup.headers.map(column => {
                            const {key, ...restColumn} = column.getHeaderProps();
                            return (
                              <th key={key} {...restColumn}>
                                {column.render('Header')}
                                <div style={{width: '100%'}}>{column.canFilter ? column.render('Filter') : null}</div>
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>
                  <tbody {...getTableBodyProps}>
                    {page.map(row => {
                      prepareRow(row);
                      const {key, ...restRowProps} = row.getRowProps();
                      return (
                        <tr key={key} {...restRowProps} onClick={() => handleRowClick(row.original)}>
                          {row.cells.map(cell => {
                            const {key, ...restCellProps} = cell.getCellProps();
                            return (
                              <td key={key} {...restCellProps} style={cell.column.style}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                  </button>
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                  </button>
                  <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                    {'>>'}
                  </button>
                  <span>
                    Página{' '}
                    <strong>
                      {pageIndex + 1} de {pageOptions.length}
                    </strong>
                  </span>
                  <select
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    }}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Mostrar {pageSize}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{marginTop: '35px', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px'}}>
                  <div style={{textAlign: 'center'}}>
                    <CustomButton
                      onPress={title === 'CONSULTA CATEGORÍAS' ? handleExport : handleDeleteCategory}
                      title={title === 'CONSULTA CATEGORÍAS' ? 'EXPORTAR XLS' : 'ELIMINAR'}
                      color={colors.MARRON_OSCURO} // Ajusta el color según sea necesario
                      fontSize={18} // Ajusta el tamaño de la fuente si es necesario
                      buttonWidth={200} // Ajusta el ancho del botón si es necesario
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {popupOpenError && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
        {popupOpenYesorNo && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{backgroundColor: buttonColor, padding: '20px', borderRadius: 40, width: '300px', height: '250px'}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button
                  className="button-mensaje"
                  style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                  onClick={() => {
                    handlePopupYesorNo();
                    handlePopupCloseError();
                  }}>
                  SI
                </button>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}} onClick={handlePopupCloseError}>
                  NO
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CrudCategories;
