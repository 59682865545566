import React, { useState, useEffect, useMemo , useCallback} from "react";
 import { useTable, useFilters, useGlobalFilter, usePagination } from 'react-table';
import * as XLSX from "xlsx";
import { useHistory } from "react-router-dom";
import Loader from "react-js-loader";
import Header from "./Header";
import { colors } from "../utils/index";
import "./Main.css";
// eslint-disable-next-line
import trashcan from '../../assets/imgs/TRASH-01.png'
import CustomButton from '../utils/CustomButton';
const ReportUser = () => {
	// eslint-disable-next-line
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const mincel = window.innerWidth < 900;
	const [popupOpenError, setPopupOpenError] = useState(false);
	const [popupOpenYesorNo, setPopupOpenYesorNo] = useState(false);
	const [mensaje, setMensaje] = useState("");
	const [selectAllHeader, setSelectAllHeader] = useState(false);
		// eslint-disable-next-line
	const [buttonColor, setButtonColor] = useState(colors.RED);
	const [title, setTitle] = useState("CONSULTA USUARIOS");
	const [titleExcel, setTitleExcel] = useState("");
// eslint-disable-next-line
	const [filteredColumn, setFilteredColumn] = useState("");
	const [responseData, setResponseData] = useState([]);
		// eslint-disable-next-line
	const [dni, setDni] = useState(null);
	// eslint-disable-next-line
	const [selectAll, setSelectAll] = useState(false);
    const [dnisToDelete, setDnisToDelete] = useState([]);
	const [filteredRecords, setFilteredRecords] = useState([]);
	const [formBackgroundColor, setFormBackgroundColor] = useState(colors.MARRON_OSCURO);


const defaultColumn = useMemo(
  () => ({
    Filter: ({column}) => {
      const {filterValue, setFilter} = column;
      return <input value={filterValue || ''} onChange={e => setFilter(e.target.value || undefined)} placeholder={`Filtrar por ${column.Header}`} />;
    },
    filter: (rows, columnIds, filterValue) => {
		return rows.filter(row => {
		const normalizedFilterValue = filterValue.toLowerCase();
			const rowValue = row.values[columnIds[0]];
		if (columnIds[0] === 'sexo') {
         if (filterValue.toLowerCase() === 'f') return rowValue === '1';
         if (filterValue.toLowerCase() === 'm') return rowValue === '2';
         if (filterValue.toLowerCase() === 'o') return rowValue === '3';
         return true; // No aplica filtro si no coincide
       }
		if (columnIds[0] === 'loginType') {
		if ('member'.startsWith(normalizedFilterValue) && rowValue === '10') return true;
		if ('evolution'.startsWith(normalizedFilterValue) && rowValue === '12') return true;
		if ('boleteria'.startsWith(normalizedFilterValue) && rowValue === '102') return true;
		if ('guardia'.startsWith(normalizedFilterValue) && rowValue === '103') return true;
		if ('barman'.startsWith(normalizedFilterValue) && rowValue === '104') return true;
		if ('marca'.startsWith(normalizedFilterValue) && rowValue === '105') return true;
		return false; // Si no coincide, debe excluirse
		}
        // Filtrado por coincidencias parciales
        return String(rowValue).toLowerCase().includes(String(filterValue).toLowerCase());
      });
    },
  }),
  [],
);


 useEffect(() => {
   const loadAllUsers = async () => {
     try {
		 setIsLoading(true);

       const body = {};
       const response = await fetch(
         "https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/consultUsersClub",
         {
           method: "POST",
           headers: {
             "Content-Type": "application/json",
           },
           body: JSON.stringify(body),
         }
       );
       const data = await response.json();
       setIsLoading(false);
       if (data.error) {
         if (data.error === "No se encontraron User") {
           setMensaje("No hay User");
           setTitle("REGISTRA REFERIDOS");
           setPopupOpenError(true);
         } else if (data.error !== "No se encontraron User") {
           setMensaje("Error en carga de User");
           setTitle("REGISTRA REFERIDOS");
           setPopupOpenError(true);
         }
       } else {
         setResponseData(data.data || []); // Ensure responseData is an array
         setFilteredRecords(data.data || []);
       }
     } catch (error) {
       console.error("Error al cargar los dnis:", error);
     }
   };

   loadAllUsers();
 }, []);

	const handleSelectReferido = useCallback(
    (dniId) => {
      // Toggle selection of a voucher
      if (dnisToDelete.includes(dniId)) {
        // If already selected, remove from selection
        setDnisToDelete(dnisToDelete.filter((dni) => dni !== dniId));
      } else {
        // If not selected, add to selectiondnisToDelete
        setDnisToDelete([...dnisToDelete, dniId]);
      }
    },
    [dnisToDelete]
  );

  const handleSelectAll = useCallback(() => {
    const newValue = !selectAllHeader;
    setSelectAllHeader(newValue);
    setSelectAll(newValue);
    const allReferidoIds = responseData.map((referido) => referido.dni); // Obtener todos los IDs de los vouchers
    setDnisToDelete(newValue ? allReferidoIds : []);

    // Marcar todos los vouchers si newValue es true, de lo contrario, vaciar la lista de IDs
    // Llamar a handleDeleteVoucher para eliminar todos los vouchers marcados
  }, [selectAllHeader, responseData ]);

 const columns = useMemo(
   () => [
     {
       Header: () => (
         <div style={{marginTop: '-20px'}}>
           {title !== 'CONSULTA USUARIOS' && <input type="checkbox" checked={selectAll} onChange={() => handleSelectAll()} />}
           {titleExcel}
         </div>
       ),
       disableFilters: true,
       Cell: ({row}) => title !== 'CONSULTA USUARIOS' && <input type="checkbox" checked={selectAllHeader || selectAll || dnisToDelete.includes(row.original.dni)} onChange={() => handleSelectReferido(row.original.dni)} />,
       accessor: 'checkbox',
     },
     {
       Header: 'TIPO',
       accessor: 'loginType',
       style: {textAlign: 'left'},
       Cell: ({value}) => (value === '10' ? 'MEMEBER' : value === '12' ? 'EVOLUTION' : value === '102' ? 'BOLETERIA' : value === '103' ? 'GUARDIA' : value === '104' ? 'BARMAN' : 'MARCA'),
     },
     {Header: 'DNI', accessor: 'dni', style: {textAlign: 'left'}},
     {Header: 'NOMBRE', accessor: 'firstName', style: {textAlign: 'left'}},
     {Header: 'APELLIDO', accessor: 'lastName', style: {textAlign: 'left'}},
     {Header: 'REFERIDO', accessor: 'pr', style: {textAlign: 'left'}},
     {
       Header: 'FECHA NACIMIENTO',
       accessor: 'birthDate',
       style: {textAlign: 'center'},
     },
     {
       Header: 'SEXO',
       accessor: 'sexo',
       style: {textAlign: 'center'},
       Cell: ({value}) => (value === '1' ? 'F' : value === '2' ? 'M' : 'O'), // Convert 1, 2, 3 to F, M, O
     },
     {
       Header: 'INSTAGRAM',
       accessor: 'ig',
       style: {textAlign: 'center'},
     },
   ],
   [dnisToDelete, handleSelectReferido, handleSelectAll, selectAll, selectAllHeader, title, titleExcel],
 );

const tableData = useMemo(() => {
  return {
    columns,
    data: Array.isArray(responseData) ? responseData : [],
    defaultColumn,
  };
}, [responseData, columns, defaultColumn]);



 const {
   getTableProps,
   getTableBodyProps,
   headerGroups,
   rows,
   prepareRow,
   page, // Use the current page data
   canPreviousPage,
   canNextPage,
   pageOptions,
   gotoPage,
   nextPage,
   previousPage,
   setPageSize,
   state: { pageIndex, pageSize },
 } = useTable(tableData, useFilters, useGlobalFilter, usePagination);

	useEffect(() => {
    if (title === "ELIMINA USUARIOS") {
      setFilteredRecords(
        rows
          .map((row) => row.original)
          .filter((record) => dnisToDelete.includes(record.dni))
      );
    } else {
      setFilteredRecords(rows.map((row) => row.original));
    }
  }, [rows, dnisToDelete, title, setPageSize]);


// eslint-disable-next-line
    const handleExport = () => {
      if (filteredRecords !== null && filteredRecords !== "SIN MOVIMIENTOS") {
        const filteredData =
          filteredRecords.length > 0
            ? filteredRecords
            : rows.map((row) => row.original);
        if (filteredData.length > 0) {
          exportToExcel(filteredData);
        } else {
          // Mostrar mensaje de que no hay datos para exportar
          console.log("No hay datos para exportar");
        }
      }
    };



	const exportToExcel = (filteredRows) => {

	 const exportData = filteredRows.map((row) => ({
     TIPO: row.loginType,
     DNI: row.dni, // Convert to uppercase
     NOMBRE: row.firstName.toUpperCase(),
     APELLIDO: row.lastName.toUpperCase(),
     REFERIDOS: row.pr.toUpperCase(),
     BIRTHDATE: row.birthDate.toUpperCase(),
     SEXO: row.sexo,
     IG: row.ig,
   }));

   const worksheet = XLSX.utils.json_to_sheet(exportData);
   const workbook = XLSX.utils.book_new();
   XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
   // Insert additional rows at the end for calculations

   // Calculate the sum of payValue column

   XLSX.writeFile(workbook, "report.xlsx");
 };

 // eslint-disable-next-line
 const filteredRowLength = filteredRecords.length;


if (localStorage.dni === "") {
  localStorage.clear();
  document.location.href = "/Login";
}
// eslint-disable-next-line
  const handleDeleteReferido = async () => {
	  try {
	 setIsLoading(true);
		var body = [];
		for (const record of filteredRecords) {
           var bodyItems = {};
           bodyItems["dni"] = record.dni;
           body.push(bodyItems);
          }
//(body)
     const response = await fetch("https://mocaub1h8d.execute-api.us-east-1.amazonaws.com/prod/deleteUsersClub",
       {
         method: "DELETE",
         headers: {
           "Content-Type": "application/json",
         },
         body: JSON.stringify(body),
       }
     );
     const data = await response.json();
		  if (Array.isArray(data) && data.length > 0) {
			  const messageObject = data[0];
			//  console.log(messageObject);

			  if (messageObject.status === "error") {
				  setButtonColor(colors.RED);
				  setMensaje("Error en la eliminación, inténtelo de nuevo.");
				  setPopupOpenError(true);
			  } else {
				  setResponseData((prevData) => {
					  // Filtrar los datos para eliminar los que se han seleccionado
					  const newData = prevData.filter(
						  (referido) => !dnisToDelete.includes(referido.dni)
					  );
					  return newData;
				  });
				  setMensaje("Eliminación exitosa");
				  setButtonColor(colors.GREEN);
				  setPopupOpenError(true);
			  }
		  }
		setIsLoading(false);
		setDnisToDelete([]);
	} catch (error) {
		console.error("Error al cargar los dni:", error);
	}
	};


const handlePopupCloseError = () => {
  //setPayEliminarNo(false)
  setPopupOpenError(false);
  setPopupOpenYesorNo(false);
};

	const handlePopupYesorNo = async () => {
		if (title === "ELIMINA USUARIOS") {
			handleDeleteReferido();
		} else {
			handleExport();
	}
};


	const handleSubmit = () => {
    // Create an array to store selected dnis
    const selectedReferidoIds = [];

    // Iterate over the rows to find selected rows and push their dnis to the array
    rows.forEach((row) => {
      if (row.isSelected) {
        selectedReferidoIds.push(row.original.idKey); // Assuming 'idKey' is the field containing dni
      }
    });

    // If no rows are selected, show error message
		if (filteredRecords.length === 0) {
			if (title === 'ELIMINA USUARIOS') {
 				setMensaje("No se han seleccionado Registro para Eliminar.");
			} else {
				 setMensaje("No se han seleccionado Registro para Exportar.");
			}

		setButtonColor(colors.RED);
		setPopupOpenError(true);
		return;
    }

    // If only one row is selected, set the dni for deletion
		if (filteredRecords.length === 1) {
			if (title === "CONSULTA USUARIOS") {
        setMensaje("CONSULTA USUARIOS?");
      } else {
        setMensaje("ELIMINA USUARIOS?");
      }
      setDni(selectedReferidoIds[0]);
		} else {

     if (title === "CONSULTA USUARIOS") {
       setMensaje("CONSULTA " + filteredRecords.length + " USUARIOS?");
     } else {
       setMensaje("ELIMINA " + filteredRecords.length + " USUARIOS?");
     }

    }

    setButtonColor(colors.RED);
    setPopupOpenYesorNo(true);
  };


const handleAtualizapup = async () => {
	setFormBackgroundColor(colors.MARRON_OSCURO);
	setTitle("CONSULTA USUARIOS");
	setTitleExcel("")

};

const handleElimina = async () => {
	setFormBackgroundColor(colors.LIGHT_GREEN);
	setTitle("ELIMINA USUARIOS");
	setTitleExcel("TODOS");
};


	const back = colors.backGroundImage;

  return (
    <div style={{backgroundImage: `url(${back})`, backgroundSize: 'cover', height: mincel ? '100vh' : '100vh', width: '100%'}}>
      <Header pageTitle={title} showBackButton={true} />
      {isLoading && (
        <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.8)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1000}}>
          <Loader type="spinner-circle" bgColor={colors.WHITE} color={colors.BACKG_Color} size={100} />
        </div>
      )}
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', marginTop: '30px'}}>
        {/* Contenido */}
        <div style={{zIndex: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{bottom: 0, padding: 10, backgroundSize: 'cover', width: mincel ? '100vw' : '90vw', flex: 1, marginTop: mincel ? 0 : '10px', flexDirection: 'row', zIndex: 990, maxWidth: '90vw', backgroundColor: formBackgroundColor, borderRadius: '50px'}}>
            {/* Botones de pestaña */}
            <div style={{position: 'absolute', marginTop: '-50px', marginLeft: '50px', display: 'flex'}}>
              <button onClick={handleAtualizapup} className="button-pest" style={{backgroundColor: colors.MARRON_OSCURO}}>
                CONSULTA
              </button>
              <button onClick={handleElimina} className="button-pest" style={{backgroundColor: colors.LIGHT_GREEN}}>
                ELIMINAR
              </button>
            </div>

            <div style={{marginTop: '20px'}}>
              <div style={{color: '#fff', display: 'grid', alignItems: 'center', backgroundColor: formBackgroundColor, padding: 20, borderRadius: 20, maxHeight: '70vh', overflow: 'auto'}}>
                <table {...getTableProps()} style={{maxWidth: '100%', marginTop: '2%'}}>
                  <thead>
                    {headerGroups.map(headerGroup => {
                      const {key, ...restHeaderGroupProps} = headerGroup.getHeaderGroupProps();
                      return (
                        <tr key={key} {...restHeaderGroupProps}>
                          {headerGroup.headers.map(column => {
                            const {key, ...restColumn} = column.getHeaderProps();
                            return (
                              <th key={key} {...restColumn}>
                                {column.render('Header')}
                                <div style={{width: '100%'}}>{column.canFilter ? column.render('Filter') : null}</div>
                              </th>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </thead>
                  <tbody {...getTableBodyProps}>
                    {page.map(row => {
                      prepareRow(row);
                      const {key, ...restRowProps} = row.getRowProps();
                      return (
                        <tr key={key} {...restRowProps}>
                          {row.cells.map(cell => {
                            const {key, ...restCellProps} = cell.getCellProps();
                            return (
                              <td key={key} {...restCellProps} style={cell.column.style}>
                                {cell.render('Cell')}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="pagination">
                  <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                  </button>
                  <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                  </button>
                  <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                  </button>
                  <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                    {'>>'}
                  </button>
                  <span>
                    Página{' '}
                    <strong>
                      {pageIndex + 1} de {pageOptions.length}
                    </strong>
                  </span>
                  <select
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                    }}>
                    {[10, 20, 30, 40, 50].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Mostrar {pageSize}
                      </option>
                    ))}
                  </select>
                </div>

                <div style={{marginTop: '35px', alignItems: 'center', display: 'flex', justifyContent: 'flex-start', marginBottom: '10px'}}></div>
              </div>
            </div>
          </div>
          <div style={{textAlign: 'center'}}>
            <CustomButton
              onPress={handleSubmit}
              title={title === 'CONSULTA USUARIOS' ? 'EXPORTAR XLS' : 'ELIMINAR SELECCIONADOS'}
              color={colors.MARRON_OSCURO} // Ajusta el color según sea necesario
              fontSize={18} // Ajusta el tamaño de la fuente si es necesario
              buttonWidth={280} // Ajusta el ancho del botón si es necesario
              buttonStyle={{padding: 0, cursor: 'pointer', border: 'none'}} // Estilos adicionales si es necesario
            />
          </div>
        </div>
        {/* Popups de error y confirmación */}
        {popupOpenError && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{width: '80%', backgroundColor: buttonColor, padding: '20px', borderRadius: 40, maxWidth: '300px', height: 250}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}} onClick={handlePopupCloseError}>
                  OK
                </button>
              </div>
            </div>
          </div>
        )}

        {popupOpenYesorNo && (
          <div style={{zIndex: 999, borderRadius: 30, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{backgroundColor: buttonColor, padding: '20px', borderRadius: 40, width: '300px', height: '250px'}}>
              <p style={{fontFamily: 'Como-Bold', marginTop: '20%', color: 'white', fontSize: '1.5em', textAlign: 'center'}}>{mensaje}</p>
              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20%'}}>
                <button
                  className="button-mensaje"
                  style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em'}}
                  onClick={() => {
                    handlePopupYesorNo();
                    handlePopupCloseError();
                  }}>
                  SI
                </button>
                <button className="button-mensaje" style={{backgroundColor: colors.BLACK, color: colors.WHITE, fontSize: '1.5em', marginLeft: '10px'}} onClick={handlePopupCloseError}>
                  NO
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportUser;
